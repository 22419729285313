import { Link, graphql, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import React from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { InlineWidget } from "react-calendly";
import { Helmet } from "react-helmet";
import { AiFillGoogleCircle } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { HiShoppingBag } from "react-icons/hi";
import Faq from "../../components/faq";
import Hero from "../../components/hero";
import Layout from "../../components/layout";
import OASection from "../../components/our-approach-section";
import WhyChooseRJMSection from "../../components/why-choose-rjm-section";
import yellowOne from "../../images/1-yellow.svg";
import yellowTwo from "../../images/2-yellow.svg";
import yellowThree from "../../images/3-yellow.svg";

const GoogleAdsPageDubai = () => {
	const data = useStaticQuery(graphql`
		query {
			heroImg: wpMediaItem(title: { eq: "Hero-Homepage" }) {
				altText
				mediaDetails {
					height
					width
				}
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			sideImg1: wpMediaItem(title: { eq: "Google-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ninjaImg: wpMediaItem(title: { eq: "Ninjaarena-fullpage" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			ninjaLogoImg: wpMediaItem(title: { eq: "ninjaarenalogo1" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			weddingImg: wpMediaItem(title: { eq: "weddingcomanylogo" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}

			needHelpImage: wpMediaItem(title: { eq: "need-help-ads" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			adsImage: wpMediaItem(title: { eq: "ads-image" }) {
				altText
				link
				gatsbyImage(
					width: 1920
					quality: 75
					formats: [WEBP]
					layout: CONSTRAINED
					placeholder: BLURRED
				)
			}
			wpSeoPage(title: { eq: "Google Ads Dubai" }) {
				contentForHeadTag {
					content
				}
				seo {
					metaDesc
					title
					twitterImage {
						altText
						mediaDetails {
							height
							width
						}
						link
						gatsbyImage(
							width: 1920
							quality: 75
							formats: [WEBP]
							layout: CONSTRAINED
							placeholder: BLURRED
						)
					}
					opengraphTitle
					opengraphDescription
				}
			}
			icon: wpMediaItem(title: { eq: "icon" }) {
				link
			}

			site {
				siteMetadata {
					siteUrl
				}
			}
		}
	`);

	const siteUrl = data.site.siteMetadata.siteUrl;
	const schema = data.wpSeoPage.contentForHeadTag.content;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: "1",
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: "2",
				name: "Dubai",
				item: {
					url: `${siteUrl}/dubai`,
					id: `${siteUrl}/dubai`,
				},
			},
			{
				"@type": "ListItem",
				position: "3",
				name: "Google Ads",
				item: {
					url: `${siteUrl}/dubai/google-ads`,
				},
			},
		],
	};

	return (
		<>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<Helmet>
				{" "}
				<script type="application/ld+json">{schema}</script>
			</Helmet>
			{/* <LocalBusinessSchema /> */}
			<GatsbySeo
				title={data.wpSeoPage.seo.title}
				description={data.wpSeoPage.seo.metaDesc}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/dubai/google-ads`,
					title: `${data.wpSeoPage.seo.opengraphTitle}`,
					description: `${data.wpSeoPage.seo.opengraphDescription}`,
					images: [
						{
							url: `${data.wpSeoPage.seo.twitterImage.link}`,
							width: `${data.wpSeoPage.seo.twitterImage.mediaDetails.width}`,
							height: `${data.wpSeoPage.seo.twitterImage.mediaDetails.height}`,
							alt: `${data.wpSeoPage.seo.twitterImage.altText}`,
						},
					],
				}}
			/>

			<Layout dubai>
				<section>
					<Hero
						dubai
						button1="primary-link-dark"
						button1Text="Get in touch"
						button1Link="/dubai/contact-us"
						button1Var="white"
						button2={null}
						button3={null}
						backgroundImage={data.heroImg.gatsbyImage}
						title={`GOOGLE ADS AGENCY \n<span class="text-secondary">DUBAI</span>`}
						description="Effective ad campaigns to make your business grow."
					/>
				</section>
				{/* <section className="py-5 py-lg-7 bg-light-grey d-none d-lg-block">
					<Container className="p-5 bg-white">
						<Row className="justify-content-center gy-4">
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#intro"
								>
									Introduction
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#benefits"
								>
									Benefits
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#results"
								>
									Results achieved
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#approach"
								>
									Our Approach
								</a>
							</Col>
							<Col className="text-center" md={4} lg={true}>
								<a
									className="text-primary ssp-regular fs-5 border-top-yellow"
									href="#faq"
								>
									FAQ's
								</a>
							</Col>
						</Row>
					</Container>
				</section> */}
				<section id="intro" className="bg-light-grey py-5 py-lg-7">
					<Container>
						<Row className="align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={6} className=" ">
								<h2 className="display-5 text-primary mb-5">
									Ads that generate high-quality traffic and leads
								</h2>
								<p>
									Using Google ads in the right way can be a powerful tool for
									your business in Dubai.
								</p>
								<p>
									At RJM Digital, we create successful Pay-Per-Click campaigns
									using Google Ads and Microsoft Ads, driving more traffic to
									your website and ultimately leading to increased revenue for
									your business. Our expertise in these platforms ensures that
									we can provide you with effective and efficient campaigns,
									generating high-quality leads from the most popular search
									engines in Dubai.
								</p>
							</Col>
							<Col lg={6}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #00000027",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.needHelpImage.gatsbyImage}
										alt={data.needHelpImage?.altText}
									/>
								</div>
							</Col>
						</Row>
					</Container>
				</section>
				<section id="benefits" className="py-5 py-lg-7 bg-dark-grey">
					<Container className="mb-8">
						<Row className="justify-content-between align-items-center">
							<Col md={6} lg={8}>
								<h2 className="display-2 ssp-bold text-white mb-lg-5">
									BENEFITS OF
									<span>
										<br /> <span className="text-secondary">GOOGLE ADS</span>
									</span>
								</h2>
							</Col>
							{/* <Col md={6} lg={4} className="text-end">
								<StaticImage
									src="../../images/Google-Ads-Icon.svg"
									placeholder="blurred"
									quality={100}
									className="w-100"
								/>
							</Col> */}
						</Row>
						<Row className="gx-xl-9 gy-5 justify-content-center">
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowOne} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Become visible</h3>
									<p className="text-white">
										Take your website to the very top of Google without having
										to wait to see results from your SEO strategy.
									</p>
								</div>
							</Col>
							<Col className="position-relative" md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute text-center w-100 h-100"
								>
									<img src={yellowTwo} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Targeted</h3>
									<p className="text-white">
										Get your Ads to your target audience when they are searching
										using specific keywords, so that your ad spend gets the best
										return possible.
									</p>
								</div>
							</Col>
							<Col className="position-relative " md={6} lg={4}>
								<div
									style={{ top: "-7rem" }}
									className="position-absolute w-100 text-center h-100"
								>
									<img src={yellowThree} className="w-md-40 w-30" />
								</div>
								<div className="px-xl-5 pt-5 position-relative">
									<h3 className="fs-1 text-white">Measurable</h3>
									<p className="text-white">
										See how your campaign is performing and what improvements
										you can make through setting and tracking your conversions
										through Google ads.
									</p>
								</div>
							</Col>
						</Row>
					</Container>
				</section>

				<section id="results" className="bg-light-grey py-5 py-lg-10">
					<Container>
						<Row className=" align-items-center gx-6 gx-lg-6 gx-xl-7">
							<Col lg={5}>
								<div
									style={{
										overflow: "hidden",
										borderRadius: "15px",
										boxShadow: "0px 3px 99px #0000001D",
									}}
								>
									<GatsbyImage
										className="w-100"
										image={data.adsImage.gatsbyImage}
										alt={data.adsImage?.altText}
									/>
								</div>
							</Col>
							<Col lg={7} className=" ">
								<h2 className="display-5 text-primary mb-5 mt-5 mt-lg-0">
									Creating ads that convert
								</h2>
								<p>
									At RJM Digital we tailor each campaign to meet your marketing
									goals. Whether you want to achieve an increase in traffic, a
									smaller cost-per-lead or build more creative ads, RJM Digital
									are here to guide you along the journey. We have a team of
									experts that will take care of the whole project whilst
									working with you to ensure every ad campaign is aligned to
									your business.
								</p>
								<Button
									className=" w-100 cta-btn w-md-auto mt-4 fs-5 white-link-yellow py-2 px-4 "
									variant="primary"
									as={Link}
									to="/dubai/contact-us"
								>
									GET IN TOUCH
								</Button>
								{/* <Row className="pt-5 g-6">
									<Col lg={4}>
										<div className=" w-100 d-flex  align-items-center justify-content-center text-center">
											<GatsbyImage
												className="w-100"
												image={
													data.ninjaLogoImg.gatsbyImage
												}
												alt={data.ninjaLogoImg?.altText}
											/>
										</div>
									</Col>
									<Col lg={4}>
										<div className=" w-100 d-flex  align-items-center justify-content-center text-center">
											<GatsbyImage
												className="w-100"
												image={
													data.weddingImg.gatsbyImage
												}
												alt={data.weddingImg?.altText}
											/>
										</div>
									</Col>
								</Row> */}
							</Col>
						</Row>
					</Container>
				</section>
				<section className="bg-primary py-5 py-lg-7">
					<Container>
						<Row className="justify-content-center">
							<Col lg={8} xl={6} className="text-center">
								<p className="ssp-bold text-uppercase text-light-blue">
									GOOGLE ADS
								</p>
								<h2 className="display-5 text-white mb-5 ">
									Types of ads we manage
								</h2>
							</Col>
						</Row>
						<Row className="mb-5 justify-content-lg-between justify-content-center gy-5">
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<BiSearch className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Search
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Get your website to the top of the search results and only pay
									once a customer clicks on it.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<HiShoppingBag className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Shopping
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Promote your E-commerce products with eye-catching visual ads
									on different search engines.
								</p>
							</Col>
							<Col md={6} lg={4} xl={3} className="text-md-center ">
								<AiFillGoogleCircle className="display-3 text-white" />
								<h3 className="ssp-bold text-white mb-4 mt-2 my-md-4">
									Google Network
								</h3>
								<p style={{ fontSize: "100%" }} className="text-white">
									Use Google owned websites such as Gmail, as well as Google
									approved third party sites to advertise your business.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="text-center">
								<Button
									className={`mt-4 mt-md-5 fs-5 w-100  w-md-auto cta-btn py-2 px-4 primary-link-dark `}
									variant="white"
									as={Link}
									to="/dubai/contact-us"
								>
									Get in touch
								</Button>
							</Col>
						</Row>
					</Container>
				</section>
				<WhyChooseRJMSection
					experienced="Our team has had many years of experience creating ads that stand out and drive traffic in order to get your business the results it wants to achieve."
					bespoke="We will customise each ad campaign to suit your business's needs and achieve your marketing goals."
					provenResults="At RJM Digital we focus on being result-driven. Regardless of whether you want more leads or greater brand awareness, we can deliver the results you want to see through pay-per-click ad campaigns."
					reliable="Our friendly team will always be there to support you with any questions you might have concerning your ads."
					transparent="Along the process of your ad campaign, we will always keep you updated on the progress as well as any alterations that may need to be made to improve and develop your ads."
					trustworthy="We are a marketing agency you can count on. We arrange regular meetings with you to discuss how the campaigns are doing and how we can keep them on track to achieve your marketing goals."
				/>
				<div id="approach">
					<OASection
						dubai
						imgHeight="60rem"
						image={data.ninjaImg.gatsbyImage}
						imageAlt={data.ninjaImg?.altText}
						heading4="REPORTING"
						launch="We provide monthly reports on the performance of each ad campaign and schedule review meetings to discuss the results with you. This allows us to identify what is working well and areas that need improvement."
						heading3="CAMPAIGN MANAGMENT"
						build="Throughout the campaign, we continuously review and optimise the ad to maximise your budget. We keep in contact with you to discuss any necessary changes or recommendations to enhance your campaign."
						heading2="CREATING THE ADS"
						design="After finalising the campaign plan with you, we can begin crafting the Ads with compelling copy, attractive images, and utilising all available features to make them visually appealing."
						brief="We conduct thorough research on your ideal customers to identify their search queries related to your business services. After that, we look at what the estimated cost-per-click will be for each campaign and work with you to decide which ones we should prioritise."
					/>
				</div>
				{/* <div className="mt-5 mt-lg-7">
					<Logos />
				</div> */}
				<section className="pt-5 pt-lg-7  bg-light-grey">
					<Container>
						<Row id="form">
							<Col className="text-center">
								{" "}
								<h2 className="display-5 text-primary mb-3">
									Talk to an expert
								</h2>
								<p>
									Book a time and day that works for you through our online
									calendar to discuss your project.
								</p>
							</Col>
						</Row>
						<Row>
							<Col className="d-xl-none">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "1150px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
							<Col className="d-none d-xl-block">
								<InlineWidget
									className="calender"
									styles={{ minWidth: "100%", height: "700px" }}
									url="https://calendly.com/robbie-rjm-digital/rjm-discovery-meeting-uae?hide_gdpr_banner=1"
								/>
							</Col>
						</Row>
					</Container>
				</section>
				{/* <section className="pt-5 pb-md-5 py-lg-7 bg-light-grey">
          <ReviewSection page="Google Ads" />
        </section> */}
				<div id="faq">
					<Faq page="Google Ads" />
				</div>
				{/* <OSSection
          dubai
          link2="/dubai/web-design"
          text2="WEB DESIGN"
          link3="/training"
          text3="TRAINING"
          image={data.sideImg1.gatsbyImage}
          imageAlt={data.sideImg1?.altText}
        /> */}
			</Layout>
		</>
	);
};

export default GoogleAdsPageDubai;
